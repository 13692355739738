<template>
  <div style="height: 100%;">
    <div
      class="login-container"
      :style="{
        backgroundImage: 'url(' + bg3 + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        height:'auto'
      }"
    >
      <div class="login-form-box">
        <h2 class="title">
          {{ doText }}
        </h2>
        <el-form
          ref="loginForm"
          :model="loginForm"
          :rules="loginRules"
          class="login-form"
          auto-complete="on"
          label-position="left"
        >
          <el-form-item prop="phone" class="username">
            <div style="display: flex; align-items: center">
              <i class="el-icon-user"></i>
              <el-input
                class="phone"
                ref="username"
                v-model="loginForm.phone"
                placeholder="请输入您的手机号"
                name="phone"
                type="text"
                tabindex="1"
                clearable
              />
            </div>
          </el-form-item>
          <el-form-item class="password" v-if="!isShowCode">
            <div style="display: flex; align-items: center">
              <i class="el-icon-lock"></i>
              <el-input
                :key="passwordType"
                ref="password"
                v-model="loginForm.password"
                :type="passwordType"
                placeholder="请输入密码"
                name="password"
                tabindex="2"
                auto-complete="on"
                @keyup.enter.native="handleLogin"
              />
            </div>
          </el-form-item>
          <el-form-item prop="code" v-if="isShowCode">
            <div class="inputs">
              <i class="el-icon-picture-outline"></i>
              <el-input
                v-model="loginForm.code"
                placeholder="请输入图形验证码"
                class="borderNone"
              ></el-input>
              <img :src="urls" alt @click="upImg" />
            </div>
          </el-form-item>
          <el-form-item prop="verificationCode" v-if="isShowCode">
            <div class="inputs">
              <i class="el-icon-message"></i>
              <el-input
                type="password"
                v-model="loginForm.verificationCode"
                placeholder="请输入验证码"
                class="borderNone"
              ></el-input>
              <el-button
                type="primary"
                round
                size="small"
                @click="send"
                :disabled="(disabled = !show)"
              >
                <div style="width: 70px" v-show="show">获取验证码</div>
                <div style="width: 70px" v-show="!show" class="count">
                  {{ count }} s
                </div>
              </el-button>
            </div>
          </el-form-item>
          <div class="tip">
            <div class="phone_pdw" @click="isShowCode = !isShowCode">
              {{ isShowCode ? '密码登录' : '短信登录' }}
            </div>
          </div>
          <el-button
            :loading="loading"
            class="login-btn"
            @click.native.prevent="handleLogin"
            >{{ doText }}</el-button
          >
        </el-form>
        <div class="tip">
          <span @click="toRegister">注册账号</span>
          <span @click="forgetPassword">忘记密码</span>
        </div>
      </div>
    </div>
    <!-- 底部     -->
    <elFooter></elFooter>
  </div>
</template>

<script>
import elFooter from '@/components/footer'
import { getImageCode, reportSms } from '@/api/login'
import { getUser } from '@/api/user'

export default {
  name: 'Login',
  props: {
    doText: {
      type: String,
      default: '登录',
    },
  },
  data() {
    const validateUsername = (rule, value, callback) => {
      const reg = /^1[3456789]\d{9}$/
      if (value === '' || !reg.test(value)) {
        return callback(new Error('请输入正确格式的手机号码 '))
      } else {
        callback()
      }
    }
    const validateCode = (rule, value, callback) => {
      if (value.length !== 6) {
        callback(new Error('请输入六位验证码'))
      } else {
        callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (value.length !== 6) {
        callback(new Error('请输入最少六位登录密码'))
      } else {
        callback()
      }
    }
    return {
      bg3: require('@/assets/images/logo-bg.png'),
      loginForm: {
        phone: '',
        password: '',
        verificationCode: '',
        type: 3,
        code: '',
      },
      showPassword: true,
      value: '+86',
      options: [
        {
          value: '+86',
        },
        {
          value: '+086',
        },
        {
          value: '+0086',
        },
      ],
      radio: false,
      loginRules: {
        phone: [
          {
            required: true,
            trigger: 'blur',
            validator: validateUsername,
          },
        ],
        verificationCode: [
          {
            required: true,
            trigger: 'blur',
            validator: validateCode,
          },
        ],
        password: [
          {
            required: true,
            trigger: 'blur',
            validator: validatePassword,
          },
        ],
      },
      loading: false,
      passwordType: 'password',
      redirect: undefined,
      timeNum: 60,
      timer: null,
      fromPath: '',
      isShowCode: false,
      urls: '',
      show: true,
      count: '',
    }
  },
  components: { elFooter },
  computed: {
    text() {
      return this.timeNum === 60 ? '获取' : `${this.timeNum} 后重新获取`
    },
    isWait() {
      return this.timeNum === 60 ? false : true
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fromPath = from.path
    })
  },
  created() {
    this.upImg()
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },

  methods: {
    upImg() {
      getImageCode().then((res) => {
        this.urls = res.data.img
        this.uuid = res.data.uuid
      })
    },
    send() {
      const time_count = 60
      let data = {
        code: this.loginForm.code,
        phone: this.loginForm.phone,
        type: 1,
        uuid: this.uuid,
      }
      reportSms(data)
        .then((res) => {
          if (res.code == 200) {
            this.$message.success('发送成功')
            this.countDown(time_count)
          }
        })
        .catch((err) => {
          this.upImg()
        })
    },
    countDown(times) {
      if (!this.timer) {
        this.count = times
        this.show = false
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= times) {
            this.count--
          } else {
            this.show = true
            clearInterval(this.timer) // 清除定时器
            this.timer = null
          }
        }, 1000)
      }
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true
          if (this.isShowCode === true) {
            this.loginForm.type = 2
          }
          this.$store
            .dispatch('user/login', this.loginForm)
            .then(() => {
              this.loading = false
              this.$message({
                message: '登录成功',
                type: 'success',
              })
              getUser().then((res) => {
                sessionStorage.setItem('userInfo',JSON.stringify(res.data))
                this.$store.commit('user/SET_AVATAR', res.data.headImage)
              })
              setTimeout(() => {
                this.$router.replace('/')
              }, 500)
            })
            .catch(() => {
              this.loading = false
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    init() {
      this.loginForm = {
        phone: '',
        password: '',
      }
    },
    showPwd() {
      this.showPassword = !this.showPassword
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    getCode() {
      let that = this
      this.timer = setInterval(() => {
        that.setNum()
      }, 1000)
    },
    setNum() {
      if (this.timeNum === 0) {
        clearInterval(this.timer)
        this.timeNum = 60
      } else {
        this.timeNum--
      }
    },
    toRegister() {
      this.$router.push({
        path: '/forgetPassword',
        query: {
          type: 1,
        },
      })
    },
    forgetPassword() {
      this.$router.push({
        path: '/forgetPassword',
        query: {
          type: 2,
        },
      })
    },
  },
}
</script>

<style lang="stylus" scoped>
.login-container
  min-height calc(100vh - 210px)
  display flex
  flex-direction row-reverse
  .title
    font-size 18px
    color #333
    padding-bottom 10px
    text-align center
  .tip
    font-size 12px
    margin 20px 0
    display flex
    justify-content space-between
    div
      cursor pointer
    span
      cursor pointer
  .show-pwd
    position absolute
    right 10px
    top 7px
    font-size 16px
    color $dark_gray
    cursor pointer
    user-select none
</style>

<style lang="stylus">
.login-container
  color #666
  .bottom-text
    text-align center
    font-size 12px
    .el-radio
      margin 0
    .el-radio__label
      padding 0
  .el-form-item__error
    z-index 999
  .login-form-box
    width 400PX
    background #fff
    padding 30px
    margin 8% 15% 0 0
    height fit-content
    border-radius 10px
    .el-form-item
      margin-bottom 0
      border-bottom 1px solid #D0D0D2
      .inputs
        display flex
        align-items center
        img
          width 100px
          height 40px
        .el-button--primary
          background-color #3c60bc
          border-color #3c60bc
      .select-box
        width 66px
        height 56px
        align-items center
        position absolute
        display flex
        left 0
        top 0
        z-index 2
        .el-input__inner
          padding 0 28px 0 10px
        .line
          color #333
      .code
        align-items center
        position absolute
        display flex
        height 56px
        right 0
        top 0
        z-index 2
    .login-btn
      width 100%
      background #3C60BC
      color #fff
  input
    border none
    outline none
    line-height 56px
    height 56px
</style>
